import { useEffect } from 'react';

import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useRequireAuth } from 'lib/hooks/useAuth';
import createSelector from 'selectorator';
import * as R from 'ramda';
import { toNsRequests } from 'lib/redux/formatters/notarization-requests';

// import { loadDashboard } from '../../utils/organization';

import moment from 'moment';
import useListenPresence from 'lib/hooks/useListenPresence';

const sentThisMonth = R.filter(R.pipe(R.prop('dateSent'), (dateSent) => moment(dateSent).isSame(new Date(), 'month')));

const completedSelector = createSelector(
  ['requests.byId'],
  R.pipe(toNsRequests, R.filter(R.pipe(R.prop('status'), R.flip(R.contains)(['complete_success']))), sentThisMonth)
);

const pendingSelector = createSelector(
  ['requests.byId'],
  R.pipe(
    toNsRequests,
    R.filter(R.pipe(R.prop('status'), R.flip(R.contains)(['pending_verification', 'ready', 'pending_start', 'in_progress', 'pending_payment']))),
    sentThisMonth
  )
);

export default function Dashboard() {
  const dispatch = useDispatch();
  const completedNsRequests = useSelector(completedSelector);
  const pendingNsRequests = useSelector(pendingSelector);

  useRequireAuth();

  useListenPresence({ page: 'dashboard', auth: useRequireAuth().getAuth() });

  useEffect(() => {
    dispatch({ type: 'requests/getRequestsRequest', payload: { status: 'pending-or-completed' } });
  }, []);

  const completedDocumentsData = {
    labels: ['Pending Notarizations', 'Completed Notarizations'],
    datasets: [
      {
        backgroundColor: ['rgb(0, 111, 131)', 'rgb(204,153,51)'],
        borderColor: 'rgb(0, 111, 131)',
        borderWidth: 0,
        data: [pendingNsRequests.length, completedNsRequests.length, 0],
        suggestedMax: 20,
      },
    ],
  };

  return (
    <section className='section'>
      <div className='container-fluid'>
        <div className='dashboard-container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='dashboard-title' style={{ marginBottom: '3rem' }}>
                <h1 style={{ textAlign: 'center', fontSize: '45px' }}>Dashboard</h1>
              </div>
              <div className='dashboard-stats-grid' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div className='dashboard-stats-grid-item'>
                  <div className='dashboard-stats'>
                    <div className='dashboard-stats-thumb'>
                      <img alt='' src='/static/img/icons/big/time2.svg' />
                    </div>
                    <div className='dashboard-stats-content'>
                      <h6>{pendingNsRequests.length}</h6>
                      <p>Pending Notarizations</p>
                    </div>
                  </div>
                </div>
                <div className='dashboard-stats-grid-item'>
                  <div className='dashboard-stats'>
                    <div className='dashboard-stats-thumb'>
                      <img alt='' src='/static/img/icons/big/copy.svg' />
                    </div>
                    <div className='dashboard-stats-content'>
                      <h6>{completedNsRequests.length}</h6>
                      <p>Notarizations This Month</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='col-lg-6' style={{ width: '67%', maxWidth: 'none', flex: 'none' }}>
              <div className='chart-block'>
                <div className='chart-block-header'>Completed Documents</div>
                <div className='chart-block-body'>
                  <div className='chart-wrapper'>
                    <Bar
                      data={completedDocumentsData}
                      options={{
                        title: {
                          display: false,
                          text: 'Notarization Requests',
                          fontSize: 20,
                        },
                        legend: {
                          display: false,
                          position: 'right',
                        },
                        max: 20,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
